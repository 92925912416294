<template>
  <v-dialog v-model="restDialog" persistent max-width="600">
    <v-card v-if="mode === 'main'">
      <v-card-title>
        <span class="headline">休憩の選択</span>
      </v-card-title>
      <v-card-text>
        <div class="d-flex">
          <v-btn block dark color="orange" class="ma-2" style="height: 150px;" @click="selectedRest(60)">
            <span class="title">休憩 60分</span>
          </v-btn>
          <v-btn block dark color="orange" class="ma-2" style="height: 150px;" @click="selectedRest(45)">
            <span class="title">休憩 45分</span>
          </v-btn>
          <v-btn block dark color="orange" class="ma-2" style="height: 150px;" @click="selectedRest(30)">
            <span class="title">休憩 30分</span>
          </v-btn>
        </div>
        <div class="d-flex">
          <v-btn block dark color="orange" class="ma-2" style="height: 150px;" @click="selectedRest(15)">
            <span class="title">休憩 15分</span>
          </v-btn>
          <v-btn block dark color="gray" class="ma-2" style="height: 150px;" @click="selectedRest(0)">
            <span class="title">休憩なし</span>
          </v-btn>
          <v-btn block light color="gray" class="ma-2" style="height: 150px;" @click="mode = 'other'">
            <span class="title">その他</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-title>
        <span class="headline">休憩の選択</span>
      </v-card-title>
      <v-card-text>
        <div class="d-flex">
          <v-btn block dark color="orange" class="ma-2" style="height: 150px;" @click="onSelected(150)">
            <span class="title">休憩 150分</span>
          </v-btn>
          <v-btn block dark color="orange" class="ma-2" style="height: 150px;" @click="onSelected(135)">
            <span class="title">休憩 135分</span>
          </v-btn>
          <v-btn block dark color="orange" class="ma-2" style="height: 150px;" @click="onSelected(120)">
            <span class="title">休憩 120分</span>
          </v-btn>
        </div>
        <div class="d-flex">
          <v-btn block dark color="orange" class="ma-2" style="height: 150px;" @click="onSelected(105)">
            <span class="title">休憩 105分</span>
          </v-btn>
          <v-btn block dark color="orange" class="ma-2" style="height: 150px;" @click="onSelected(90)">
            <span class="title">休憩 90分</span>
          </v-btn>
          <v-btn block dark color="orange" class="ma-2" style="height: 150px;" @click="selectedRest(75)">
            <span class="title">休憩 75分</span>
          </v-btn>
          <v-btn block light color="gray" class="ma-2" style="height: 150px;" @click="mode = 'main'">
            <span class="title">戻る</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: [ 'restDialog', 'selectedRest' ],
  data: () => ({
    mode: 'main'
  }),
  methods: {
    onSelected (m) {
      this.mode = 'main'
      this.selectedRest(m)
    }
  }
}
</script>
